import React from 'react';

export type SwitchOperation = (A: number, B: number) => [number, number];

interface PuzzleSolverProps {
  startNumber: number;
  expectedA: number;
  expectedB: number;
  switchOperations: SwitchOperation[];
}

const PuzzleSolver: React.FC<PuzzleSolverProps> = ({
  startNumber,
  expectedA,
  expectedB,
  switchOperations
}) => {
  const applySwitches = (switches: number[]) => {
    let A = startNumber;
    let B = startNumber;
    switches.forEach(switchIndex => {
      const operation = switchOperations[switchIndex];
      [A, B] = operation(A, B);
    });
    return [A, B];
  };

  const findSolutions = () => {
    const solutions: number[][] = [];
    const availableSwitches = switchOperations.map((_, index) => index);

    for (let r = 1; r <= availableSwitches.length; r++) {
      const testCombinations = (combo: number[], index: number) => {
        if (combo.length === r) {
          const [A, B] = applySwitches(combo);
          if (A === expectedA && B === expectedB) {
            solutions.push(combo.map(i => i + 1));
          }
          return;
        }

        for (let i = index; i < availableSwitches.length; i++) {
          testCombinations([...combo, availableSwitches[i]], i + 1);
        }
      };

      testCombinations([], 0);
    }

    return solutions;
  };

  const solutions = findSolutions();

  return (
    <div>
      <h1>Puzzle Solutions:</h1>
      {solutions.map((solution, index) => (
        <div key={index}>Solution {index + 1}: {solution.join(', ')}</div>
      ))}
    </div>
  );
};

export default PuzzleSolver;
