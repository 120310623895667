import React, { useState } from 'react';
import './App.css';
import PuzzleSolver, { SwitchOperation } from './PuzzleSolver';

function App() {
  const [startNumber, setStartNumber] = useState<number | string>('');
  const [expectedA, setExpectedA] = useState<number | string>('');
  const [expectedB, setExpectedB] = useState<number | string>('');
  const [switchOperationsInput, setSwitchOperationsInput] = useState<string[]>(Array(16).fill(''));
  const [switchOperations, setSwitchOperations] = useState<SwitchOperation[]>([]);
  const [calculate, setCalculate] = useState<boolean>(false);

  const startNum = isNaN(Number(startNumber)) ? undefined : Number(startNumber);
  const expA = isNaN(Number(expectedA)) ? undefined : Number(expectedA);
  const expB = isNaN(Number(expectedB)) ? undefined : Number(expectedB);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newSwitchOperationsInput = [...switchOperationsInput];
    newSwitchOperationsInput[index] = e.target.value;
    setSwitchOperationsInput(newSwitchOperationsInput);
  };

  const handleSubmit = () => {
    if (isNaN(Number(startNumber)) || isNaN(Number(expectedA)) || isNaN(Number(expectedB))) {
      alert('Please enter valid numbers for Start Number, Expected A, and Expected B.');
      return;
    }


    const switchOps: SwitchOperation[] = [];
    for (let i = 0; i < switchOperationsInput.length; i += 2) {
      const [opA, opB] = [switchOperationsInput[i], switchOperationsInput[i + 1]];
      switchOps.push((A: number, B: number) => [
        applyOperation(A, opA),
        applyOperation(B, opB)
      ]);
    }
    setSwitchOperations(switchOps);
    setCalculate(true);
  };

  const applyOperation = (value: number, operation: string) => {
    const operator = operation.charAt(0);
    const operand = parseFloat(operation.substring(1));
    switch (operator) {
      case '+':
        return value + operand;
      case '-':
        return value - operand;
      case '*':
        return value * operand;
      case '/':
        return value / operand;
      default:
        return value; // Return the original value if the operation is not recognized
    }
  };

  return (
    <div className="App">
      <label>Start Number: </label>
      <input type="text" value={startNumber} onChange={e => setStartNumber(e.target.value)} />
      <br />
      <label>Expected A: </label>
      <input type="text" value={expectedA} onChange={e => setExpectedA(e.target.value)} />
      <br />
      <label>Expected B: </label>
      <input type="text" value={expectedB} onChange={e => setExpectedB(e.target.value)} />
      <br />
      {Array.from({ length: 8 }, (_, i) => (
        <div key={i}>
          <label>Switch {i + 1} (A): </label>
          <input type="text" value={switchOperationsInput[i * 2]} onChange={e => handleInputChange(e, i * 2)} />
          <label> (B): </label>
          <input type="text" value={switchOperationsInput[i * 2 + 1]} onChange={e => handleInputChange(e, i * 2 + 1)} />
          <br />
        </div>
      ))}
      <button onClick={handleSubmit}>Calculate</button>
      {calculate && startNum !== undefined && expA !== undefined && expB !== undefined && (
        <PuzzleSolver
          startNumber={startNum}
          expectedA={expA}
          expectedB={expB}
          switchOperations={switchOperations}
        />
      )}
    </div>
  );
}

export default App;
// const switchOperations : SwitchOperation[] = [
//   (A: number, B: number) => [A, B - 40],
//   (A: number, B: number) => [A, B],
//   (A: number, B: number) => [A - 40, B * 2],
//   (A: number, B: number) => [A + 20, B * 2],
//   (A: number, B: number) => [A + 40, B * 2],
//   (A: number, B: number) => [A * 2, B],
//   (A: number, B: number) => [A * 2, B - 60],
//   (A: number, B: number) => [A / 2, B * 2]
// ];

// startNumber={80}
// expectedA={60}
// expectedB={320}